import React, { useState, useEffect } from "react"
import MotherTongueDialog from "../Lang/MotherTongueDialog"
import userStore from "./UserStore"

function MotherTongueChecker() {
    const [isMotherTongueOpen, setIsMotherTongueOpen] = useState(false)
    const isLoaded = userStore((state) => state.isLoaded)
    const motherTongue = userStore((state) => state.motherTongue)

    useEffect(() => {
        if (isLoaded && motherTongue === "") {
            setIsMotherTongueOpen(true)
        }
    }, [isLoaded, motherTongue])

    return (
        <MotherTongueDialog
            isOpen={isMotherTongueOpen}
            onClose={() => setIsMotherTongueOpen(false)}
            allowClose={false}
        />
    )
}

export default MotherTongueChecker
