import React, { useEffect } from "react"
import { Link } from "gatsby"
import * as Tabs from "@radix-ui/react-tabs"

import { useDiaryStepsQuery } from "./DiaryService"
import useDiaryStepsStore from "./DiaryStepsStore"

import DraftStep from "../DiarySteps/DraftStep"
import MotherTongueReplaceStep from "../DiarySteps/MotherTongueReplaceStep"
import MistakeCorrectionStep from "../DiarySteps/MistakeCorrectionStep"
import FinalStep from "../DiarySteps/FinalStep"
import MenuIcon from "../icons/MenuIcon"
import { useTranslation } from "react-i18next"
import MyButton from "../ui/MyButton"

function DiaryStepsPage({ diaryId }) {
    const { isLoading, error, data } = useDiaryStepsQuery(diaryId)
    const setDiary = useDiaryStepsStore((state) => state.setDiary)
    const diary = useDiaryStepsStore((state) => state.diary)
    const activeTab = useDiaryStepsStore((state) => state.activeTab)
    const setActiveTab = useDiaryStepsStore((state) => state.setActiveTab)

    const { t } = useTranslation("diarySteps")

    useEffect(() => {
        if (data) {
            setDiary(data)
        }
    }, [data])

    function renderStep(item) {
        switch (item.step) {
            case "draft":
                return <DraftStep item={item} />
            case "mother_tongue_replace":
                return <MotherTongueReplaceStep item={item} />
            case "mistake_correction":
                return <MistakeCorrectionStep item={item} />
            case "final":
                return <FinalStep item={item} />
        }
    }

    function changeStepHandler(value) {
        setActiveTab(value)
    }

    return (
        <div className="container mx-auto mt-16 pt-2">
            <Tabs.Root
                // defaultValue={activeTab}
                value={activeTab}
                orientation="vertical"
                className="flex flex-row space-x-8 justify-center items-center mt-8"
                onValueChange={(value) => changeStepHandler(value)}
            >
                <Tabs.List className="flex flex-col ml-4">
                    {diary &&
                        diary.steps &&
                        diary.steps.map((item, index) => (
                            <Tabs.Trigger
                                value={item.id}
                                key={"trigger_" + item.id}
                            >
                                <Tab
                                    item={item}
                                    isActive={activeTab === item.id}
                                />
                            </Tabs.Trigger>
                        ))}
                </Tabs.List>

                <div className="flex flex-grow flex-col pr-4">
                    <MyButton href="/diary" isLink className="mb-8">
                        {t("diarySteps:viewAllEntries")}
                    </MyButton>

                    {diary &&
                        diary.steps &&
                        diary.steps.map((item, index) => (
                            <Tabs.Content
                                className="flex flex-grow space-x-8 "
                                value={item.id}
                                key={item.id}
                            >
                                {renderStep(item, index)}
                            </Tabs.Content>
                        ))}
                </div>
            </Tabs.Root>
        </div>
    )
}

function tabStepTitle(step) {
    switch (step) {
        case "draft":
            return "Draft"
        case "mother_tongue_replace":
            return "Native Replace"
        case "mistake_correction":
            return "Mistakes"
        case "final":
            return "Final"
    }
}

export function Tab({ item, isActive }) {
    return (
        <div
            className={`flex items-center group lg:h-6 space-x-2 ${
                isActive ? "" : "hidden lg:visible"
            }`}
        >
            <div
                className={`lg:w-24 text-xs text-right leading-3 group-hover:opacity-100 transition-opacity duration-300 ${
                    isActive ? "opacity-100" : "opacity-0"
                }`}
            >
                {tabStepTitle(item.step)}
            </div>
            <div
                className={`w-3 h-3 rounded-full ${
                    isActive ? "bg-gray-900" : "bg-gray-400"
                } group-hover:bg-gray-600 transition-colors duration-300`}
            ></div>
        </div>
    )
}
export default DiaryStepsPage

export const enDiaryStepsResources = {
    diarySteps: {
        viewAllEntries: "Back to All Entries",
    },
}

export const ruDiaryStepsResources = {
    diarySteps: {
        viewAllEntries: "Вернутся ко всем записям",
    },
}
