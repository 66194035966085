import { useEffect } from "react"

import { useUserQuery } from "./UserService"
import userStore from "./UserStore"
import useAuthStore from "../Auth/AuthStore"
import { navigate } from "gatsby"
import { useTranslation } from "react-i18next"
import { t } from "i18next"

function UserLoader() {
    const setUser = userStore((state) => state.setUser)
    const { isLoading, error, data } = useUserQuery()
    const isLoggedIn = useAuthStore((state) => state.isLoggedIn)
    const { i18n } = useTranslation()

    useEffect(() => {
        if (data) {
            setUser(data)

            i18n.changeLanguage(data.motherTongue)

            window.SLEEK_USER = {
                token: data.seekplanSSO,
            }

            window?.$sleek?.setUser({
                token: data.seekplanSSO,
            })
        }
    }, [data])

    useEffect(() => {
        if (isLoggedIn === null || (isLoggedIn !== null && !isLoggedIn)) {
            navigate("/", {
                replace: true,
                state: { unauthorized: true },
            })
        }
    }, [isLoggedIn])
}

export default UserLoader
