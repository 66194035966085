import { create } from "zustand"
import { persist } from "zustand/middleware"

const useUserStore = create(
    persist(
        (set, get) => ({
            isLoaded: false,
            id: "",
            email: "",
            name: "",
            motherTongue: "",
            lang: "",
            langs: [],
            seekplanSSO: "",
            setUser: (data) => set({ ...data, isLoaded: true }),
            setMotherTongue: (motherTongue) =>
                set({ motherTongue: motherTongue }),
            setLang: (lang) =>
                set((state) => {
                    const newLangs = state.langs.filter((l) => l !== lang)
                    return {
                        lang: lang,
                        langs: [lang, ...newLangs],
                    }
                }),
            addLang: (lang) =>
                set({ langs: [lang, ...get().langs], lang: lang }),
        }),
        {
            name: "user-storage", // уникальное имя для хранилища
            getStorage: () => localStorage, // использовать localStorage для хранения
        }
    )
)

export default useUserStore
