import useAuthStore from "../components/Auth/AuthStore"
export const Api = process.env.REACT_APP_API_URL

export const config = {
    googleRedirectUrl:
        process.env.REACT_APP_GOOGLE_REDIRECT_URL ||
        "http://localhost:8087/auth/google",
    isDev: process.env.REACT_APP_IS_DEV === "true",
    api: process.env.REACT_APP_API_URL,
}

console.log("Api", Api)
console.log("IsDev", config.isDev)

// Вспомогательная функция для получения токена из localStorage
const getAuthToken = () => localStorage.getItem("token")

// Вспомогательная функция для выполнения аутентифицированных запросов
export const authenticatedFetch = async (url, options = {}) => {
    const token = getAuthToken()
    const headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
    }

    const response = await fetch(url, { ...options, headers })

    if (response.status === 401) {
        // Здесь нужно вызвать функцию для выхода пользователя и редиректить его на главную страницу
        // localStorage.removeItem("token")
        useAuthStore.getState().logout()
        throw new Error("unauthorized")
    }

    return response
}

// export const cards = (filter) => {
//     const params = new URLSearchParams(filter).toString()
//     return fetch(`${Api}/cards?${params}`).then((res) => res.json())
// }

export const updateCard = (card) => {
    return fetch(Api + "/cards/" + card.id, {
        method: "POST",
        body: JSON.stringify(card),
    })
}

export const deleteCard = (cardId) => {
    return fetch(Api + "/cards/" + cardId, {
        method: "DELETE",
    })
}

export const cardsStats = (cardIds) => {
    return fetch(Api + "/cards/stats", {
        method: "POST",
        body: JSON.stringify({ cardIds: cardIds }),
    }).then((res) => res.json())
}

export const studyCard = (cardId, kind) => {
    return fetch(Api + "/cards/" + cardId + "/study/" + kind, {
        method: "PUT",
    }).then((res) => res.json())
}

export const answerCard = (cardId, kind, answer) => {
    return fetch(Api + "/cards/" + cardId + "/answer/" + answer, {
        method: "PUT",
        body: JSON.stringify({ answer: answer, kind: kind }),
    }).then((res) => res.json())
}

export const translateCard = (cardId) => {
    return fetch(Api + "/cards/" + cardId + "/translate", {
        method: "GET",
    }).then((res) => res.json())
}
