import {
    GB,
    CN,
    ES,
    FR,
    DE,
    SA,
    JP,
    PT,
    RU,
    IT,
    GR,
    IL,
    TR,
} from "country-flag-icons/react/1x1"

export const langs = new Map([
    ["en", { code: "en", name: "English", flag: GB }],
    ["es", { code: "es", name: "Spanish", flag: ES }],
    ["zh", { code: "zh", name: "Chinese", flag: CN }],
    ["fr", { code: "fr", name: "French", flag: FR }],
    ["de", { code: "de", name: "German", flag: DE }],
    ["ar", { code: "ar", name: "Arabic", flag: SA }],
    ["ja", { code: "ja", name: "Japanese", flag: JP }],
    ["pt", { code: "pt", name: "Portuguese", flag: PT }],
    ["ru", { code: "ru", name: "Russian", flag: RU }],
    ["it", { code: "it", name: "Italian", flag: IT }],
    ["el", { code: "el", name: "Greek", flag: GR }],
    ["he", { code: "he", name: "Hebrew", flag: IL }],
    ["tr", { code: "tr", name: "Turkish", flag: TR }],
])

export const langsFromCodes = (codes) => {
    return codes
        .filter((code) => langs.has(code))
        .map((code) => langs.get(code))
}

export const isRtl = (code) => {
    return code == "ar" || code == "he"
}
