import React, { useCallback, useMemo } from "react"
import { createEditor } from "slate"
import { Slate, Editable, withReact, DefaultElement } from "slate-react"
import { withHistory } from "slate-history"
import { useTranslation } from "react-i18next"

const Editor = ({ initialValue, isWritable, onChangeHandler }) => {
    const editor = useMemo(() => withHistory(withReact(createEditor())), [])
    const { t } = useTranslation("editor")

    if (initialValue === null || initialValue.length === 0) {
        initialValue = [
            {
                type: "paragraph",
                children: [
                    {
                        text: "",
                    },
                ],
            },
        ]
    }

    const renderElement = useCallback((props) => {
        switch (props.element.type) {
            case "paragraph":
                return <ParagraphElement {...props} />
            case "sentence":
                return <SentenceElement {...props} />
            default:
                console.log("Unknown element", props)
                return <DefaultElement {...props} />
        }
    })

    const renderLeaf = useCallback((props) => {
        return <Leaf {...props} />
    }, [])

    return (
        <Slate
            editor={editor}
            initialValue={initialValue}
            onChange={onChangeHandler}
        >
            <Editable
                className="focus:outline-none"
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                placeholder={t("editor:placeholder")}
                readOnly={!isWritable}
            />
        </Slate>
    )
}

const ParagraphElement = ({ attributes, children }) => {
    return (
        <p {...attributes} className="mb-4">
            {children}
        </p>
    )
}

const SentenceElement = ({ attributes, children, element }) => {
    return <span {...attributes}>{children}</span>
}

const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.isHighlight) {
        attributes = {
            ...attributes,
            className: "text-accent font-semibold",
        }
    }

    if (leaf.isFade) {
        attributes = {
            ...attributes,
            className: "text-gray-400",
        }
    }
    return <span {...attributes}>{children}</span>
}

export default Editor

export const enEditorResources = {
    editor: {
        placeholder: "Share your thoughts here... ",
    },
}

export const ruEditorResources = {
    editor: {
        placeholder: "Поделитесь своими мыслями здесь... ",
    },
}

// Английские варианты:

// "Share your thoughts..."
// "Your language journey continues..."
// "Express yourself here..."
// "Your words matter..."
// "Ideas welcome..."

// Русские варианты:

// "Поделитесь мыслями..."
// "Ваше языковое путешествие продолжается..."
// "Выразите себя здесь..."
// "Ваши слова важны..."
// "Идеи приветствуются..."
