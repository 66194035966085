import React from "react"
import * as Dialog from "@radix-ui/react-dialog"
import { X } from "lucide-react"
import MyButton from "../ui/MyButton"
import GoogleAuth from "./GoogleAuth"
import { useTranslation } from "react-i18next"

const AuthDialog = ({ isOpen, onClose }) => {
    const { t } = useTranslation("authDialog")

    return (
        <Dialog.Root open={isOpen} onOpenChange={onClose}>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 bg-black/50" />
                <Dialog.Content
                    className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-3xl shadow-lg bg-white w-3/4 lg:w-[800px] h-[500px] flex overflow-hidden"
                    onPointerDownOutside={onClose}
                    onEscapeKeyDown={onClose}
                >
                    {/* Левый блок */}
                    <div className="hidden lg:visible w-1/2 bg-gray-100 p-8 lg:flex flex-col justify-center items-center rounded-l-3xl">
                        <p className="text-xl font-semibold absolute top-6 left-8">
                            Gloisia
                        </p>
                        <h2 className="text-2xl text-center">
                            {t("authDialog:welcome")}
                        </h2>
                    </div>
                    {/* Правый блок */}
                    <div className="w-full lg:w-1/2 p-8 flex flex-col justify-center items-center">
                        <Dialog.Title className="text-2xl font-bold mb-4">
                            {t("authDialog:title")}
                        </Dialog.Title>
                        <p className="text-center mb-6 text-sm">
                            {t("authDialog:description")}
                        </p>
                        <GoogleAuth>
                            {t("authDialog:googleAuthButton")}
                        </GoogleAuth>
                    </div>

                    <Dialog.Close aria-label="Close" asChild>
                        <div className="absolute top-5 right-5">
                            <MyButton isIcon={true}>
                                <X size={16} />
                            </MyButton>
                        </div>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    )
}

export default AuthDialog

export const enAuthDialogResources = {
    authDialog: {
        welcome: "Welcome!",
        title: "Sign In / Sign Up",
        description:
            "Sign in to your account or register to continue learning foreign languages.",
        googleAuthButton: "Continue with Google",
    },
}

export const ruAuthDialogResources = {
    authDialog: {
        welcome: "Добро пожаловать!",
        title: "Вход / Регистрация",
        description:
            "Войдите в свой аккаунт или зарегистрируйтесь, чтобы продолжить изучение иностранных языков.",
        googleAuthButton: "Продолжить с Google",
    },
}
