import React from "react"
import Editor from "./Editor"
import MyButton from "./../ui/MyButton"
import { useTranslation } from "react-i18next"
import { isRtl } from "../Lang/Definitions"

function FinalStep({ item }) {
    const { editorData } = item
    const { t } = useTranslation("common", "finalStep")

    return (
        <>
            <div className="w-full max-w-3xl">
                <div className="rounded-2xlg shadow-lg pt-12 pb-6 px-8 bg-white min-h-[260px] text-lg flex flex-col space-y-6">
                    <div
                        className="grow"
                        dir={isRtl(item.lang) ? "rtl" : "ltr"}
                    >
                        <Editor initialValue={editorData} />
                    </div>
                    <div className="flex flex-col items-center">
                        <MyButton className="opacity-0">done</MyButton>
                    </div>
                </div>
            </div>

            <div className="pt-12 pb-4 w-96 min-w-64 h-[260px] text-base whitespace-pre-line">
                {t("finalStep:motivationSpeech")}
            </div>
        </>
    )
}

export default FinalStep

export const enFinalStepResources = {
    finalStep: {
        motivationSpeech:
            "Excellent! Your text has been successfully processed, and all errors have been identified and corrected. This is an important step towards improving your writing.\n\nKeep writing regularly — it's the key to progress in the language you're learning. Every line you write brings you closer to mastery.\n\nRemember that even small daily efforts lead to significant results over time. Don't be afraid of mistakes; they are your best teachers. With each correction, you become more confident and proficient. Keep up the good work!",
    },
}

export const ruFinalStepResources = {
    finalStep: {
        motivationSpeech:
            "Отлично! Ваш текст успешно обработан, все ошибки выявлены и исправлены. Это важный шаг на пути к совершенствованию вашего письма.\n\nПродолжайте писать регулярно — это ключ к прогрессу в изучаемом языке. Каждая написанная строка приближает вас к мастерству.\n\nПомните, что даже небольшие ежедневные усилия со временем приводят к значительным результатам. Не бойтесь ошибок, они — ваши лучшие учителя. С каждым исправлением вы становитесь увереннее и грамотнее. Так держать!",
    },
}
