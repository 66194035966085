import React, { useEffect, useState, useCallback } from "react"
import * as Dialog from "@radix-ui/react-dialog"
import MyButton from "../ui/MyButton"
import useUserStore from "./../User/UserStore"
import { useUserUpdateMutation } from "../User/UserService"
import LanguageSelector from "./LanguageSelector"
import { langsFromCodes } from "./Definitions"
import { useTranslation } from "react-i18next"
import DialogCloseButton from "../ui/DialogCloseButton"

const MotherTongueDialog = ({ isOpen, onClose, allowClose = true }) => {
    const motherTongue = useUserStore((state) => state.motherTongue)
    const setMotherTongue = useUserStore((state) => state.setMotherTongue)
    const [dialogLanguage, setDialogLanguage] = useState(motherTongue)
    const { t, i18n } = useTranslation("common", "motherTongueDialog")
    const userUpdateMutation = useUserUpdateMutation()

    useEffect(() => {
        setDialogLanguage(motherTongue)
    }, [motherTongue])

    const handleCloseAttempt = useCallback(
        (event) => {
            if (!allowClose) {
                event.preventDefault()
            }
        },
        [allowClose]
    )

    function saveLanguageHandler() {
        userUpdateMutation.mutate(
            { motherTongue: dialogLanguage },
            {
                onSuccess: () => {
                    setMotherTongue(dialogLanguage)
                    onClose()
                },
            }
        )
    }

    function langChangeHandler(lang) {
        setDialogLanguage(lang)
        i18n.changeLanguage(lang)
    }

    const languages = langsFromCodes(["en", "ru"])

    return (
        <Dialog.Root open={isOpen} onOpenChange={onClose}>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 bg-black/50" />
                <Dialog.Content
                    className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-3xl shadow-lg bg-white w-[800px] h-[500px] flex overflow-hidden"
                    onPointerDownOutside={handleCloseAttempt}
                    onEscapeKeyDown={handleCloseAttempt}
                >
                    {/* Левый блок */}
                    <div className="w-1/2 bg-gray-100 p-8 flex flex-col justify-start items-center space-y-4">
                        <div className="pt-12" />
                        <LanguageSelector
                            selectedDialogLanguage={dialogLanguage}
                            setSelectedDialogLanguage={langChangeHandler}
                            languages={languages}
                        />
                    </div>

                    {/* Правый блок */}
                    <div className="w-1/2 p-8 flex flex-col justify-between items-center">
                        <div className="flex flex-col justify-center h-full">
                            <Dialog.Title className="text-2xl font-bold mb-4 text-center">
                                {t("motherTongueDialog:title")}
                            </Dialog.Title>
                            <p className="text-center mb-6 text-sm">
                                {t("motherTongueDialog:description")}
                            </p>
                        </div>

                        <MyButton
                            onClick={saveLanguageHandler}
                            loading={userUpdateMutation.isPending}
                            disabled={dialogLanguage === ""}
                        >
                            {t("common:continue")}
                        </MyButton>
                    </div>

                    {allowClose && <DialogCloseButton />}
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    )
}

export default MotherTongueDialog

export const enMotherTongueDialog = {
    motherTongueDialog: {
        title: "Your Primary Language",
        description:
            "Choose the language in which you think and express yourself most naturally. If you are equally proficient in several languages, indicate the one you use most frequently in your daily life.",
    },
}

export const ruMotherTongueDialog = {
    motherTongueDialog: {
        title: "Ваш основной язык",
        description:
            "Выберите язык, на котором вы думаете и выражаетесь наиболее естественно. Если вы владеете несколькими языками одинаково хорошо, укажите тот, который используете чаще всего в повседневной жизни.",
    },
}
