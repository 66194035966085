import React, { useCallback, useState } from "react"
import * as Dialog from "@radix-ui/react-dialog"
import MyButton from "../ui/MyButton"
import LanguageSelector from "./LanguageSelector"
import DialogCloseButton from "../ui/DialogCloseButton"
import { useTranslation } from "react-i18next"
import { langs } from "./Definitions"

import { useUserAddLangMutation } from "../User/UserService"
import userStore from "../User/UserStore"

import Scroll from "../ui/Scroll"

const LangDialog = ({ isOpen, onClose, allowClose = true }) => {
    const userAddLangMutation = useUserAddLangMutation()
    const addLang = userStore((state) => state.addLang)
    const [dialogLanguage, setDialogLanguage] = useState("")
    const { t } = useTranslation("common", "langDialog")

    const languages = Array.from(langs.values())

    function saveLanguageHandler() {
        userAddLangMutation.mutate(
            { lang: dialogLanguage },
            {
                onSuccess: () => {
                    addLang(dialogLanguage)
                    onClose()
                },
            }
        )
    }

    const handleCloseAttempt = useCallback(
        (event) => {
            if (!allowClose) {
                event.preventDefault()
            }
        },
        [allowClose]
    )

    return (
        <Dialog.Root open={isOpen} onOpenChange={onClose}>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 bg-black/50" />
                <Dialog.Content
                    className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-3xl shadow-lg bg-white w-[800px] h-[500px] flex overflow-hidden"
                    onPointerDownOutside={handleCloseAttempt}
                    onEscapeKeyDown={handleCloseAttempt}
                >
                    {/* Левый блок */}
                    <div className="w-1/2 p-8 flex flex-col justify-between items-center">
                        <div className="flex flex-col justify-center h-full">
                            <Dialog.Title className="text-2xl font-bold mb-4 text-center">
                                {t("langDialog:title")}
                            </Dialog.Title>
                            <p className="text-center mb-6 text-sm">
                                {t("langDialog:description")}
                            </p>
                        </div>

                        <MyButton
                            onClick={saveLanguageHandler}
                            loading={userAddLangMutation.isPending}
                            disabled={dialogLanguage === ""}
                        >
                            {t("common:continue")}
                        </MyButton>
                    </div>

                    {/* Правый блок */}
                    <div className="w-1/2 bg-gray-100 flex flex-col justify-start items-center space-y-4">
                        <Scroll neverHide={true} scrollbarClassName="py-6">
                            <div className="h-full w-full flex flex-col justify-start items-center space-y-4">
                                <div className="pt-16" />
                                <LanguageSelector
                                    selectedDialogLanguage={dialogLanguage}
                                    setSelectedDialogLanguage={
                                        setDialogLanguage
                                    }
                                    languages={languages}
                                />
                                <div className="pt-2" />
                            </div>
                        </Scroll>
                    </div>

                    {allowClose && <DialogCloseButton />}
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    )
}

export default LangDialog

export const enLangDialog = {
    langDialog: {
        title: "Language to learn",
        description:
            "Choose the language you want to learn or improve. This can be a language you're already somewhat familiar with or a completely new one. Remember, you can always change your choice or add other languages to learn later.",
    },
}

export const ruLangDialog = {
    langDialog: {
        title: "Язык для изучения",
        description:
            "Выберите язык, который вы хотите изучать или совершенствовать. Это может быть язык, с которым вы уже немного знакомы, или совершенно новый для вас. Помните, что вы всегда сможете изменить свой выбор или добавить другие языки для изучения позже.",
    },
}
