import React, { useEffect } from "react"
import { config } from "../api"
import MyButton from "../ui/MyButton"

import useAuthStore from "./AuthStore"
import { navigate } from "gatsby"

const GoogleAuth = (props) => {
    const { login } = useAuthStore()
    const { setIsDialogOpen } = useAuthStore()

    useEffect(() => {
        window.addEventListener("message", handleMessage)
        return () => window.removeEventListener("message", handleMessage)
    }, [])

    const handleGoogleLogin = () => {
        const width = 500
        const height = 600
        const left = window.screenX + (window.outerWidth - width) / 2
        const top = window.screenY + (window.outerHeight - height) / 2.5
        const url = config.googleRedirectUrl

        window.open(
            url,
            "googleLoginPopup",
            `width=${width},height=${height},left=${left},top=${top}`
        )
    }

    const handleMessage = (event) => {
        if (!config.isDev && event.origin !== window.location.origin) return
        if (event.data.type === "GOOGLE_AUTH_SUCCESS") {
            const { token } = event.data
            login(token)
            navigate("/diary")
            setIsDialogOpen(false)
        }
    }

    return <MyButton onClick={handleGoogleLogin}>{props.children}</MyButton>
}

export default GoogleAuth
