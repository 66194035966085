import React, { useEffect } from "react"
import AuthDialog from "../Auth/AuthDialog"
import MyButton from "../ui/MyButton"
import useAuthStore from "../Auth/AuthStore"
import { navigate } from "gatsby"

import { useTranslation } from "react-i18next"
import Header from "../ui/Header"

import { Tab } from "../Diary/DiaryStepsPage"
import {
    DraftStep,
    MotherTongueStep,
    MistakeCorrectionStep,
    FinalStep,
} from "./Editor"

// это нужно удалить но сохранить переводы?
function HomePage() {
    const { isLoggedIn } = useAuthStore()
    const { checkAuth } = useAuthStore()
    const { setIsDialogOpen } = useAuthStore()
    const { t } = useTranslation("home")

    useEffect(() => {
        checkAuth()
    }, [])

    const startUsingHandler = () => {
        if (isLoggedIn) {
            navigate(`/diary`)
        } else {
            setIsDialogOpen(true)
        }
    }

    return (
        <div className="flex flex-col bg-white">
            <Header />

            <div className="container mx-auto flex flex-row items-center mt-8 px-8">
                <div className="flex flex-col w-full">
                    <div className="rounded-2xlg shadow-lg py-6 px-8 bg-neutral min-h-[560px] w-full my-16">
                        <div className="w-full md:w-1/2 flex flex-col space-y-8 mt-12">
                            <h1 className="text-5xl">
                                Enhance Your Foreign Language Skills
                            </h1>
                            <p className="text-lg">
                                Gloisia is an application designed to improve
                                your foreign language skills. It encourages you
                                to write notes in your target language about the
                                world around you and events in your life.
                            </p>
                            <p className="text-lg">
                                Powered by Anthropic's LLM technology, we
                                analyze your entries, identify errors, and
                                suggest improvements, helping you refine your
                                language skills in the context of everyday use.
                            </p>
                        </div>
                    </div>

                    <div className="flex mt-10"></div>
                </div>
            </div>

            <div className="bg-neutral">
                <div className="container mx-auto py-24 flex px-8 flex-col lg:flex-row lg:space-x-8 space-y-4">
                    <div className="w-full lg:w-96 flex flex-col pl-6 pr-8">
                        <h3 className="text-3xl font-semibold">Key Features</h3>
                        <p className="text-base">
                            The following features of our application are
                            designed to enhance the effectiveness of foreign
                            language learning and reflect the core principles of
                            how Gloisia operates.
                        </p>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 w-full ">
                        {[
                            "Step-by-step analysis of your texts written in a foreign language allows you to focus on each aspect of the language in detail, stage by stage.",
                            "Writing about events in your life helps reveal gaps in your vocabulary and knowledge of language structures. Filling these gaps will enable you to engage in casual, everyday conversations with ease.",
                            "You don't need to worry about using words or phrases in your native language while writing notes. The app will highlight them and suggest translations that best fit the context.",
                            "Based on your texts, you will receive personalized recommendations to improve your grammar, expand your vocabulary, and refine your writing style. This will help your writing sound more natural, like that of a native speaker.",
                            "Our application utilizes Anthropic's cutting-edge LLM (Large Language Model) for intelligent text analysis and provision of personalized recommendations.",
                            "Our application supports 12 languages: English, Spanish, Chinese, French, German, Arabic, Japanese, Portuguese, Russian, Italian, Greek, and Hindi. If you are learning one of these languages, you're likely to find something useful for yourself in Gloisia.",
                        ].map((item) => (
                            <div
                                key={item}
                                className="rounded-2xlg shadow-lg py-6 px-8 bg-white text-base"
                            >
                                {item}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="container mx-auto flex flex-col px-8 py-24 space-y-12">
                <div className="flex w-full">
                    <div className="flex flex-col space-y-4 w-96 pl-6 pr-8">
                        <h3 className="text-3xl font-semibold">How it works</h3>
                        <p className="text-base">
                            Write a text in the language you're learning. Don't
                            be afraid to make mistakes or use words in your
                            native language if you don't know the translation.
                            Focus on the writing process and enjoy it. The main
                            goal is to express your thoughts and practice the
                            language.
                        </p>
                    </div>

                    <div className="bg-zinc-100 w-full rounded-2xlg p-12 flex space-x-8 items-center">
                        <div>
                            <Tab item={{ id: 1, step: "draft" }} isActive />
                        </div>
                        <div className="w-full max-w-3xl">
                            <div className="rounded-2xlg shadow-lg pt-12 pb-6 px-8 bg-white min-h-[260px] text-lg flex flex-col space-y-6">
                                <div className="grow">
                                    <DraftStep />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex w-full">
                    <div className="flex flex-col w-96 pl-6 pr-8">
                        <p className="text-base">
                            Well, well, well, what do we have here! It seems
                            we've found some words in the native language. This
                            step involves highlighting sentences where words
                            from the native language were used, as well as the
                            words themselves. Below, the user will be offered
                            translations for each of these{" "}
                            <span className="text-amber-600 font-semibold">
                                highlighted words
                            </span>{" "}
                            into the target language. For example, here's what
                            we found:
                        </p>

                        <ul className="list-none space-y-2">
                            <li>
                                <b>day</b> - día
                            </li>
                            <li>
                                <b>recharge</b> - recargar
                            </li>
                            <li>...</li>
                        </ul>
                    </div>
                    <div className="bg-zinc-100 w-full rounded-2xlg p-12 flex space-x-8 items-center">
                        <div>
                            <Tab item={{ id: 1, step: "draft" }} />
                            <Tab
                                item={{ id: 2, step: "mother_tongue_replace" }}
                                isActive
                            />
                        </div>

                        <div className="w-full max-w-3xl">
                            <div className="rounded-2xlg shadow-lg pt-12 pb-6 px-8 bg-white min-h-[260px] text-lg flex flex-col space-y-6">
                                <div className="grow">
                                    <MotherTongueStep />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex w-full">
                    <div className="flex flex-col w-96 pl-6 pr-8">
                        <p className="text-base">
                            After replacing words from the native language, we
                            will proceed to analyze vocabulary and grammar.
                        </p>
                        <ul className="list-none space-y-2 text-base">
                            <li>
                                <b>mismno</b> - It seems you have a small typo
                                here. 'Mismno' should be 'mismo', which means
                                'same' or 'self'.
                            </li>
                            <li>
                                <b>puedo</b> - After 'donde', it's better to use
                                the subjunctive form 'pueda' instead of the
                                indicative 'puedo'. This is because the
                                subjunctive is used for expressing desires,
                                doubts, or hypothetical situations.
                            </li>
                        </ul>
                    </div>
                    <div className="bg-zinc-100 w-full rounded-2xlg p-12 flex space-x-8 items-center">
                        <div>
                            <Tab item={{ id: 1, step: "draft" }} />
                            <Tab
                                item={{ id: 2, step: "mother_tongue_replace" }}
                            />
                            <Tab
                                item={{ id: 3, step: "mistake_correction" }}
                                isActive
                            />
                        </div>
                        <div className="w-full max-w-3xl">
                            <div className="rounded-2xlg shadow-lg pt-12 pb-6 px-8 bg-white min-h-[260px] text-lg flex flex-col space-y-6">
                                <div className="grow">
                                    <MistakeCorrectionStep />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex w-full">
                    <div className="flex flex-col space-y-4 w-96 pl-6 pr-8">
                        <p className="text-base">
                            The text is now fully corrected and something to be
                            proud of. We hope we've learned from it: we'll study
                            the words we were missing to tell this story, and
                            also pay attention to the grammar rules we need to
                            brush up on.
                        </p>
                    </div>
                    <div className="bg-zinc-100 w-full rounded-2xlg p-12 flex space-x-8 items-center">
                        <div>
                            <Tab item={{ id: 1, step: "draft" }} />
                            <Tab
                                item={{ id: 2, step: "mother_tongue_replace" }}
                            />
                            <Tab item={{ id: 3, step: "mistake_correction" }} />
                            <Tab item={{ id: 4, step: "final" }} isActive />
                        </div>
                        <div className="w-full max-w-3xl">
                            <div className="rounded-2xlg shadow-lg pt-12 pb-6 px-8 bg-white min-h-[260px] text-lg flex flex-col space-y-6">
                                <div className="grow">
                                    <FinalStep />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mx-auto px-4 flex flex-row space-x-4 items-center">
                <div className="flex flex-col w-full">
                    <div className="rounded-2xlg py-6 px-8 bg-zinc-100 w-full rounded-b-none">
                        <MyButton onClick={startUsingHandler} accent>
                            {t("header:startUsing")}
                        </MyButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomePage

export const enHomePageResources = {
    home: {
        startUsing: "Start Using",
        seeForYourself: "See for Yourself",
    },
}

export const ruHomePageResources = {
    home: {
        startUsing: "Начать пользоваться",
        seeForYourself: "Увидеть своими глазами",
    },
}
