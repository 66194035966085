import React, { useState, useEffect } from "react"
import AuthDialog from "../Auth/AuthDialog"
import MyButton from "../ui/MyButton"
import useAuthStore from "../Auth/AuthStore"
import { Link as GatsbyLink } from "gatsby"
import { useTranslation } from "react-i18next"
import { useIntl, Link } from "gatsby-plugin-intl"
import { useLocation } from "@reach/router"

const Header = ({ backgroundColor = "bg-white" }) => {
    const [showBlog, setShowBlog] = useState(false)

    const { isLoggedIn } = useAuthStore()
    const { isDialogOpen } = useAuthStore()
    const { setIsDialogOpen } = useAuthStore()
    const { i18n, t } = useTranslation()
    const intl = useIntl()
    const location = useLocation()

    const startUsingHandler = () => {
        setIsDialogOpen(true)
    }

    useEffect(() => {
        setShowBlog(!location.pathname.includes("schliemann-method"))
    }, [location.pathname])

    return (
        <header
            className={`fixed top-0 left-0 right-0 p-4 z-10 ${backgroundColor}`}
        >
            <div className="flex justify-between items-center container mx-auto px-8 space-x-4">
                {intl.locale == "en" ? (
                    <GatsbyLink
                        to="/"
                        className="text-xl font-semibold font-lora"
                    >
                        Gloisia
                    </GatsbyLink>
                ) : (
                    <Link
                        to="/"
                        language={false}
                        className="text-xl font-semibold font-lora"
                    >
                        Gloisia
                    </Link>
                )}

                <div className="flex flex-row items-center justify-end space-x-8">
                    {showBlog && (
                        <span className="text-end lg:text-base text-sm">
                            <span className="text-accent">New post:</span>{" "}
                            <Link
                                to="/blog/schliemann-method-modern-language-learning"
                                language={intl.locale}
                            >
                                Schliemann Method with Modern Technologies
                            </Link>
                        </span>
                    )}

                    {isLoggedIn ? (
                        <MyButton accent isLink href="/diary">
                            {t("home:startUsing")}
                        </MyButton>
                    ) : (
                        <MyButton
                            onClick={startUsingHandler}
                            accent
                            gaCategory="User"
                            gaAction="Login"
                            gaLabel="Login Button"
                        >
                            {t("home:startUsing")}
                        </MyButton>
                    )}

                    <AuthDialog
                        isOpen={isDialogOpen}
                        onClose={() => setIsDialogOpen(false)}
                    />
                </div>
            </div>
        </header>
    )
}

export default Header
