import "./src/styles/global.css"

import React from "react"
import { I18nextProvider } from "react-i18next"
import i18n from "./src/components/Lang/I18n"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

import MouseflowInitializer from "./src/components/Analytics/MouseflowInitializer"
import LogRocket from "logrocket"
import Smartlook from "smartlook-client"
import SleekScriptLoader from "./src/components/Analytics/SleekScriptLoader"

export const onClientEntry = () => {
    // if (process.env.NODE_ENV === "production") {
    //     // LogRocket
    //     LogRocket.init("okgcvb/gloisia")
    //     // Smartlook
    //     Smartlook.init("94915cabff8b55ec0a293ccd8fa0c25bbbd62d6d")
    // }
}

export const wrapRootElement = ({ element }) => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    })

    return (
        <>
            {/* <SleekScriptLoader /> */}
            {/* <MouseflowInitializer mfid={"07bcb844-ad7f-457a-a25e-2279f3a70572"}> */}
            <I18nextProvider i18n={i18n}>
                <QueryClientProvider client={queryClient}>
                    {element}
                </QueryClientProvider>
            </I18nextProvider>
            {/* </MouseflowInitializer> */}
        </>
    )
}
