import { useQuery, useMutation } from "@tanstack/react-query"
import { config, authenticatedFetch } from "../api"

export function useUserQuery() {
    return useQuery({
        queryKey: ["user"],
        queryFn: () =>
            authenticatedFetch(config.api + `/user`).then((res) => res.json()),
        retry: (failureCount, error) => {
            // Не повторяем запрос, если статус 401
            if (error.message === "unauthorized") {
                return false
            }
            // Для других ошибок повторяем запрос максимум 3 раза
            return failureCount < 3
        },
    })
}

export function useUserUpdateMutation() {
    return useMutation({
        mutationFn: async (props) => {
            const { motherTongue, lang } = props
            const response = await authenticatedFetch(config.api + "/user", {
                method: "PATCH",
                body: JSON.stringify({
                    motherTongue: motherTongue,
                    lang: lang,
                }),
            })

            if (!response.ok) {
                throw new Error(response.json())
            }

            return response.json()
        },
    })
}

export function useUserAddLangMutation() {
    return useMutation({
        mutationFn: async (props) => {
            const { lang } = props
            const response = await authenticatedFetch(
                config.api + "/user/langs",
                {
                    method: "POST",
                    body: JSON.stringify({
                        lang: lang,
                    }),
                }
            )

            if (!response.ok) {
                throw new Error(response.json())
            }

            return response.json()
        },
    })
}
