import React from "react"
import Editor from "./Editor"
import useDiaryStepsStore from "./../Diary/DiaryStepsStore"
import { useTranslation } from "react-i18next"
import { isRtl } from "../Lang/Definitions"

function MistakeCorrectionStep({ item }) {
    const { editorData, mistakes } = item
    const nextStepId = useDiaryStepsStore((state) => state.nextStepId)
    const setActiveTab = useDiaryStepsStore((state) => state.setActiveTab)
    const { t } = useTranslation(["common"])

    const processHandler = () => {
        const stepId = nextStepId(item.id)
        if (stepId) {
            setActiveTab(stepId)
        }
    }

    return (
        <>
            <div className="w-full max-w-3xl">
                <div className="rounded-2xlg shadow-lg pt-12 pb-6 px-8 bg-white w-full min-h-[260px] text-lg max-w-3xl flex flex-col space-y-6">
                    <div
                        className="grow"
                        dir={isRtl(item.lang) ? "rtl" : "ltr"}
                    >
                        <Editor initialValue={editorData} />
                    </div>
                    <div className="flex flex-col items-center">
                        <button
                            className="border-solid border rounded-lg px-3 py-2 transition duration-300 text-xs border-gray-400 text-gray-500 hover:border-gray-900 hover:text-gray-950 w-fit"
                            onClick={processHandler}
                        >
                            {t("common:next")}
                        </button>
                    </div>
                </div>
            </div>

            <div className="pt-12 pb-4 w-96 min-w-64 h-[260px] text-base">
                <div className="text-base whitespace-pre-line">
                    {mistakes && mistakes.length > 0
                        ? t("mistakeCorrectionStep:foundSomething")
                        : t("mistakeCorrectionStep:nothingFound")}
                </div>
                <ul className="list-none mt-6 space-y-2">
                    {mistakes &&
                        mistakes.map((item) => (
                            <li key={item.id}>
                                <b>{item.mistake}</b> - {item.explanation}
                            </li>
                        ))}
                </ul>
            </div>
        </>
    )
}

export default MistakeCorrectionStep

export const enMistakeCorrectionStepResources = {
    mistakeCorrectionStep: {
        foundSomething:
            "Let's focus on the following points we've noticed during the text review. These relate to grammar and syntax improvements:",
        nothingFound:
            "Excellent! Your text has no grammatical or spelling errors.",
    },
}

export const ruMistakeCorrectionStepResources = {
    mistakeCorrectionStep: {
        foundSomething:
            "Давайте сосредоточимся на следующих моментах, которые мы заметили при проверке текста. Они касаются улучшений в грамматике и синтаксисе:",
        nothingFound:
            "Превосходно! В вашем тексте нет грамматических и орфографических ошибок.",
    },
}
