import React from "react"

const LanguageSelector = ({
    selectedDialogLanguage,
    setSelectedDialogLanguage,
    languages = [],
}) => {
    return (
        <>
            {languages.map((lang) => (
                <LanguageItem
                    key={lang.code}
                    language={lang}
                    isSelected={selectedDialogLanguage === lang.code}
                    onClick={() => setSelectedDialogLanguage(lang.code)}
                />
            ))}
        </>
    )
}

const LanguageItem = ({ language, isSelected, onClick }) => {
    return (
        <button
            onClick={onClick}
            className={`rounded-3xl p-6 text-lg flex flex-col space-y-2 items-center justify-between relative transition-all duration-300 ease-in-out outline
      ${
          isSelected
              ? "shadow-lg bg-white outline-white"
              : "outline-gray-200 focus:outline-gray-300"
      }
      `}
        >
            <div className="rounded-full border border-gray-200 overflow-hidden flex">
                <language.flag className="saturate-50 brightness-125 w-14 h-14" />
            </div>
            <div
                className={`w-24 text-center transition-colors duration-300 ease-in-out
        ${isSelected ? "text-black" : "text-gray-400"}`}
            >
                {language.name}
            </div>
        </button>
    )
}

export default LanguageSelector
