import { create } from "zustand"
import { persist } from "zustand/middleware"

const useAuthStore = create(
    persist(
        (set, get) => ({
            isLoggedIn: null,
            isDialogOpen: false,
            token: null,

            setIsLoggedIn: (isLoggedIn) => set({ isLoggedIn }),
            setIsDialogOpen: (isDialogOpen) => set({ isDialogOpen }),
            setToken: (token) => set({ token }),

            login: (token) => {
                const userData = parseJwt(token)
                if (userData) {
                    localStorage.setItem("token", token)
                    set({
                        isLoggedIn: true,
                        token,
                    })
                }
            },

            logout: () => {
                localStorage.removeItem("token")
                set({
                    isLoggedIn: false,
                    token: null,
                })
            },

            checkAuth: () => {
                const token = localStorage.getItem("token")
                if (token) {
                    get().login(token)
                }
            },
        }),
        {
            name: "auth-storage", // уникальное имя для хранилища
            getStorage: () => localStorage, // использовать localStorage для хранения
        }
    )
)

function parseJwt(token) {
    try {
        const base64Url = token.split(".")[1]
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
                })
                .join("")
        )

        return JSON.parse(jsonPayload)
    } catch (e) {
        console.error("Error parsing JWT", e)
        return null
    }
}

export default useAuthStore
