import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import { enDiaryResources, ruDiaryResources } from "../Diary/DiaryPage"
import {
    enDraftStepResources,
    ruDraftStepResources,
} from "../DiarySteps/DraftStep"
import {
    enMotherTongueReplaceStepResources,
    ruMotherTongueReplaceStepResources,
} from "../DiarySteps/MotherTongueReplaceStep"
import {
    enMistakeCorrectionStepResources,
    ruMistakeCorrectionStepResources,
} from "../DiarySteps/MistakeCorrectionStep"
import {
    enMotherTongueDialog,
    ruMotherTongueDialog,
} from "./MotherTongueDialog"
import { enLangDialog, ruLangDialog } from "./LangDialog"
import { enEditorResources, ruEditorResources } from "../DiarySteps/Editor"
import { enHomePageResources, ruHomePageResources } from "../Pages/HomePage"
import {
    enAuthDialogResources,
    ruAuthDialogResources,
} from "../Auth/AuthDialog"
import { enHeaderResources, ruHeaderResources } from "../layouts/Header"
import {
    enFinalStepResources,
    ruFinalStepResources,
} from "../DiarySteps/FinalStep"
import {
    enDiaryStepsResources,
    ruDiaryStepsResources,
} from "../Diary/DiaryStepsPage"

i18n.use(initReactI18next).init({
    resources: {
        en: {
            common: {
                next: "Next",
                back: "Back",
                save: "Save",
                continue: "Continue",
            },
            ...enDiaryResources,
            ...enDraftStepResources,
            ...enFinalStepResources,
            ...enMotherTongueReplaceStepResources,
            ...enMistakeCorrectionStepResources,
            ...enMotherTongueDialog,
            ...enLangDialog,
            ...enEditorResources,
            ...enHomePageResources,
            ...enAuthDialogResources,
            ...enHeaderResources,
            ...enDiaryStepsResources,
        },
        ru: {
            common: {
                next: "Далее",
                back: "Назад",
                save: "Сохранить",
                continue: "Продолжить",
            },
            ...ruDiaryResources,
            ...ruDraftStepResources,
            ...ruFinalStepResources,
            ...ruMotherTongueReplaceStepResources,
            ...ruMistakeCorrectionStepResources,
            ...ruMotherTongueDialog,
            ...ruLangDialog,
            ...ruEditorResources,
            ...ruHomePageResources,
            ...ruAuthDialogResources,
            ...ruHeaderResources,
            ...ruDiaryStepsResources,
        },
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
})

export default i18n
