import { create } from "zustand"

const useDiaryStepsStore = create((set, get) => ({
    diary: null,
    setDiary: (diary) =>
        set((state) => {
            const activeTab =
                diary && diary.steps && diary.steps.length > 0
                    ? diary.steps[0].id
                    : ""

            return { diary: diary, activeTab: activeTab }
        }),
    addSteps: (steps, setActive) =>
        set((state) => ({
            diary: state.diary
                ? {
                      ...state.diary,
                      steps: [...state.diary.steps, ...steps],
                  }
                : null,
            activeTab: setActive ? steps[0].id : state.activeTab,
        })),

    updateStepEditorData: (stepId, newEditorData) =>
        set((state) => {
            if (!state.diary || !state.diary.steps) {
                return state
            }

            const updatedSteps = state.diary.steps.map((step) =>
                step.id === stepId
                    ? { ...step, editorData: newEditorData }
                    : step
            )

            return {
                diary: {
                    ...state.diary,
                    steps: updatedSteps,
                },
            }
        }),

    nextStepId: (currentStepId) => {
        const { diary } = get()

        // Проверяем, существует ли diary и steps
        if (!diary || !diary.steps || !Array.isArray(diary.steps)) {
            return null
        }

        const { steps } = diary
        const currentStepIndex = steps.findIndex(
            (step) => step.id === currentStepId
        )

        // Проверяем, найден ли текущий шаг и существует ли следующий
        if (currentStepIndex === -1 || currentStepIndex >= steps.length - 1) {
            return null
        }

        const nextStep = steps[currentStepIndex + 1]
        return nextStep.id
    },
    activeTab: "",
    setActiveTab: (tabId) => set({ activeTab: tabId }),
}))

export default useDiaryStepsStore
