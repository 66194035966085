import React, { useState, useEffect } from "react"
import userStore from "./UserStore"
import LangDialog from "../Lang/LangDialog"

function LangChecker() {
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const langs = userStore((state) => state.langs)
    const motherTongue = userStore((state) => state.motherTongue)
    const isLoaded = userStore((state) => state.isLoaded)

    useEffect(() => {
        if (isLoaded && motherTongue !== "" && langs.length === 0) {
            setIsDialogOpen(true)
        }
    }, [isLoaded, motherTongue, langs])

    return (
        <LangDialog
            isOpen={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
            allowClose={false}
        />
    )
}

export default LangChecker
