import React, { useState, useRef, useEffect } from "react"
import { navigate } from "gatsby"
import { Plus } from "lucide-react"
import LangDialog from "./LangDialog"
import useUserStore from "../User/UserStore"
import { langsFromCodes } from "../../components/Lang/Definitions"
import { useUserUpdateMutation } from "../User/UserService"

function LangSelector() {
    const [listHeight, setListHeight] = useState(0)
    const [isExpanded, setIsExpanded] = useState(false)
    const listRef = useRef(null)
    const lang = useUserStore((state) => state.lang)
    const langs = useUserStore((state) => state.langs)
    const setLang = useUserStore((state) => state.setLang)
    const userUpdateMutation = useUserUpdateMutation()

    var languages = langsFromCodes(langs)
    const [isLangDialogOpen, setIsLangDialogOpen] = useState(false)

    useEffect(() => {
        languages = langsFromCodes(langs)
        if (listRef.current) {
            setListHeight(listRef.current.scrollHeight)
        }
    }, [langs])

    function addLangHandler() {
        setIsLangDialogOpen(true)
    }

    function setLangHandler(newLang) {
        if (newLang === lang) return
        userUpdateMutation.mutate(
            { lang: newLang },
            {
                onSuccess: () => {
                    setLang(newLang)
                    navigate("/diary")
                    // reload or move to /diary
                    console.log("reload or move to /diary")
                },
            }
        )
    }

    return (
        <>
            <LangDialog
                isOpen={isLangDialogOpen}
                onClose={() => setIsLangDialogOpen(false)}
            />

            <div
                className="relative p-3"
                onMouseEnter={() => setIsExpanded(true)}
                onMouseLeave={() => setIsExpanded(false)}
            >
                <div
                    ref={listRef}
                    className={`flex flex-col items-end space-y-3 transition-all duration-300 ease-in-out overflow-hidden`}
                    style={{ height: isExpanded ? `${listHeight}px` : "48px" }}
                >
                    {languages.map((lang) => (
                        <button
                            key={lang.code}
                            className="rounded-full border border-gray-200 overflow-hidden flex w-10 h-10 shrink-0"
                            onClick={() => {
                                setLangHandler(lang.code)
                            }}
                        >
                            <lang.flag className="saturate-50 brightness-125 w-full h-full" />
                        </button>
                    ))}

                    <button
                        className="rounded-full border flex bg-zinc-300 w-10 h-10 justify-center items-center group hover:shadow-inner shrink-0"
                        onClick={addLangHandler}
                    >
                        <Plus className="w-6 h-6 text-gray-500 group-hover:text-gray-950 transition-colors duration-300" />
                    </button>
                </div>
            </div>
        </>
    )
}

export default LangSelector
