import React from "react"
import * as Dialog from "@radix-ui/react-dialog"
import { X } from "lucide-react"
import MyButton from "../ui/MyButton"

const DialogCloseButton = () => {
    return (
        <Dialog.Close aria-label="Close" asChild>
            <div className="absolute top-5 right-5">
                <MyButton isIcon={true}>
                    <X size={16} />
                </MyButton>
            </div>
        </Dialog.Close>
    )
}

export default DialogCloseButton
