import React, { useEffect } from "react"
import { navigate } from "gatsby"

const CustomRedirect = ({ location }) => {
    useEffect(() => {
        console.log("location", location)
        if (location.pathname.includes("/diary")) {
            const pathParts = location.pathname.split("/")
            const nonLocalePath = pathParts.slice(2).join("/")
            navigate(`/${nonLocalePath}`, { replace: true })
        }
    }, [location])

    return null
}

export default CustomRedirect
