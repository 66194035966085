import React from "react"
import Editor from "../DiarySteps/Editor"

export const DraftStep = () => {
    const initialValue = [
        {
            type: "paragraph",
            children: [
                {
                    text: "¡Hoy fue un muy buen day! Finalmente terminé mi trabajo en un proyecto importante y ahora puedo relajarme un poco. Tal vez me iré de viaje por una semana o dos para recharge energías, porque ahora mismno me siento totalmente agotado. Sería genial ir a algún lugar donde puedo descansar del trabajho y estar más cerca de la nature.",
                },
            ],
            text: null,
        },
    ]

    return <Editor initialValue={initialValue} />
}

export const MotherTongueStep = () => {
    const initialValue = [
        {
            type: "paragraph",
            children: [
                {
                    type: "sentence",
                    children: [
                        {
                            text: "¡Hoy fue un muy buen ",
                        },
                        {
                            text: "day",
                            isHighlight: true,
                        },
                        {
                            text: "! ",
                        },
                    ],
                    text: null,
                },
                {
                    type: "sentence",
                    children: [
                        {
                            text: "Finalmente terminé mi trabajo en un proyecto importante y ahora puedo relajarme un poco. ",
                            isFade: true,
                        },
                    ],
                    text: null,
                },
                {
                    type: "sentence",
                    children: [
                        {
                            text: "Tal vez me iré de viaje por una semana o dos para ",
                        },
                        {
                            text: "recharge",
                            isHighlight: true,
                        },
                        {
                            text: " energías, porque ahora mismno me siento totalmente agotado. ",
                        },
                    ],
                    text: null,
                },
                {
                    type: "sentence",
                    children: [
                        {
                            text: "Sería genial ir a algún lugar donde puedo descansar del ",
                        },
                        {
                            text: "trabajho",
                            isHighlight: true,
                        },
                        {
                            text: " y estar más cerca de la nature. ",
                        },
                    ],
                    text: null,
                },
            ],
            text: null,
        },
    ]

    return <Editor initialValue={initialValue} />
}

export const MistakeCorrectionStep = () => {
    const initialValue = [
        {
            type: "paragraph",
            children: [
                {
                    type: "sentence",
                    children: [
                        {
                            text: "¡Hoy fue un muy buen día! ",
                            isFade: true,
                        },
                    ],
                    text: null,
                },
                {
                    type: "sentence",
                    children: [
                        {
                            text: "Finalmente terminé mi trabajo en un proyecto importante y ahora puedo relajarme un poco. ",
                            isFade: true,
                        },
                    ],
                    text: null,
                },
                {
                    type: "sentence",
                    children: [
                        {
                            text: "Tal vez me iré de viaje por una semana o dos para recargar energías, porque ahora ",
                        },
                        {
                            text: "mismno",
                            isHighlight: true,
                        },
                        {
                            text: " me siento totalmente agotado. ",
                        },
                    ],
                    text: null,
                },
                {
                    type: "sentence",
                    children: [
                        {
                            text: "Sería genial ir a algún lugar donde ",
                        },
                        {
                            text: "puedo",
                            isHighlight: true,
                        },
                        {
                            text: " descansar del trabajo y estar más cerca de la naturaleza. ",
                        },
                    ],
                    text: null,
                },
            ],
            text: null,
        },
    ]

    return <Editor initialValue={initialValue} />
}

export const FinalStep = () => {
    const initialValue = [
        {
            type: "paragraph",
            children: [
                {
                    type: "sentence",
                    children: [
                        {
                            text: "¡Hoy fue un muy buen día! ",
                        },
                    ],
                    text: null,
                },
                {
                    type: "sentence",
                    children: [
                        {
                            text: "Finalmente terminé mi trabajo en un proyecto importante y ahora puedo relajarme un poco. ",
                        },
                    ],
                    text: null,
                },
                {
                    type: "sentence",
                    children: [
                        {
                            text: "Tal vez me iré de viaje por una semana o dos para recargar energías, porque ahora mismo me siento totalmente agotado. ",
                        },
                    ],
                    text: null,
                },
                {
                    type: "sentence",
                    children: [
                        {
                            text: "Sería genial ir a algún lugar donde pueda descansar del trabajo y estar más cerca de la naturaleza. ",
                        },
                    ],
                    text: null,
                },
            ],
            text: null,
        },
    ]

    return <Editor initialValue={initialValue} />
}
