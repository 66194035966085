import { useQuery, useMutation } from "@tanstack/react-query"
import { Api, authenticatedFetch } from "../api"

export function useDiaryQuery() {
    return useQuery({
        queryKey: ["diaries"],
        queryFn: () =>
            authenticatedFetch(Api + `/diaries`).then((res) => res.json()),
        onError: (error) => {
            // конкретно этот кейс нужно обрабатывать в authenticatedFetch
            if (error.message === "Unauthorized") {
                // Здесь можно добавить логику для перенаправления на страницу входа
                console.log(
                    "User is unauthorized. Redirecting to login page..."
                )
            }
        },
    })
}

export function useCreateDiaryMutation() {
    return useMutation({
        mutationFn: async (props) => {
            const response = await authenticatedFetch(Api + "/diaries", {
                method: "POST",
                body: JSON.stringify({}),
            })

            if (!response.ok) {
                throw new Error(response.json())
            }

            return response.json()
        },
    })
}

export function useDeleteDiaryMutation(diaryId) {
    return useMutation({
        mutationKey: ["deleteDiary", diaryId],
        mutationFn: async (props) => {
            const response = await authenticatedFetch(
                Api + "/diaries/" + props.id,
                {
                    method: "DELETE",
                }
            )

            if (!response.ok) {
                throw new Error(response.json())
            }

            return response.json()
        },
    })
}

export function useDiaryStepsQuery(diaryId) {
    return useQuery({
        queryKey: ["diaries", diaryId],
        queryFn: () =>
            authenticatedFetch(Api + `/diaries/${diaryId}`).then((res) =>
                res.json()
            ),
    })
}

export function useUpdateDiaryStepMutation() {
    return useMutation({
        mutationFn: (props) => {
            const { id, diaryId, editorData } = props

            authenticatedFetch(Api + `/diaries/${diaryId}/steps/${id}`, {
                method: "PATCH",
                body: JSON.stringify({ editorData: editorData }),
            }).then((res) => {
                return res.json()
            })
        },
    })
}

export function useProcessDiaryStepMutation() {
    return useMutation({
        mutationFn: async (props) => {
            const { id, diaryId } = props

            const response = await authenticatedFetch(
                Api + `/diaries/${diaryId}/steps/${id}/process`,
                {
                    method: "POST",
                    body: JSON.stringify({}),
                }
            )

            if (!response.ok) {
                console.log("Validation error", response.status)
                if (response.status === 422) {
                    const errorData = await response.json()
                    throw new Error(errorData.error, {
                        cause: errorData,
                    })
                } else {
                    throw new Error(
                        "Network response was not ok",
                        response.json()
                    )
                }
            }

            return response.json()
        },
    })
}
