import { create } from "zustand"

const useDiaryStore = create((set) => ({
    notes: new Map(),
    dates: new Map(),
    setDiaryNotes: (diaryNotes) =>
        set(() => {
            let dates = new Map()
            let notes = new Map()
            diaryNotes.forEach((note) => {
                const date = note.createdAtInfo.date
                if (!dates.has(date)) {
                    dates.set(date, {
                        day: note.createdAtInfo.day,
                        month: note.createdAtInfo.month,
                    })
                }
                if (!notes.has(date)) {
                    notes.set(date, [])
                }
                notes.get(date).push(note)
            })
            return { diaryNotes: diaryNotes, dates: dates, notes: notes }
        }),

    createDiaryNote: (diaryNote) => {
        set((state) => {
            const newDiaryNotes = [...state.diaryNotes, diaryNote]
            const date = diaryNote.createdAtInfo.date
            const newNotes = new Map(state.notes)
            if (!newNotes.has(date)) {
                newNotes.set(date, [])
            }
            newNotes.get(date).push(diaryNote)
            const newDates = new Map(state.dates)
            if (!newDates.has(date)) {
                newDates.set(date, {
                    day: diaryNote.createdAtInfo.day,
                    month: diaryNote.createdAtInfo.month,
                })
            }
            return {
                diaryNotes: newDiaryNotes,
                notes: newNotes,
                dates: newDates,
            }
        })
    },
    deleteDiaryNote: (id) => {
        set((state) => {
            const newDiaryNotes = state.diaryNotes.filter(
                (diaryNote) => diaryNote.id !== id
            )
            const newNotes = new Map(state.notes)
            newNotes.forEach((notesArray, date) => {
                newNotes.set(
                    date,
                    notesArray.filter((note) => note.id !== id)
                )
                if (newNotes.get(date).length === 0) {
                    newNotes.delete(date)
                    state.dates.delete(date)
                }
            })
            return {
                diaryNotes: newDiaryNotes,
                notes: newNotes,
                dates: state.dates,
            }
        })
    },
}))

export default useDiaryStore
