import React from "react"
import Editor from "./Editor"
import MyButton from "./../ui/MyButton"
import { useTranslation } from "react-i18next"
import { isRtl } from "../Lang/Definitions"

import { useProcessDiaryStepMutation } from "../Diary/DiaryService"

import useDiaryStepsStore from "./../Diary/DiaryStepsStore"

function MotherTongueReplaceStep({ item }) {
    const { editorData, translateSuggestions } = item
    const processDiaryStep = useProcessDiaryStepMutation()
    const nextStepId = useDiaryStepsStore((state) => state.nextStepId)
    const setActiveTab = useDiaryStepsStore((state) => state.setActiveTab)
    const addSteps = useDiaryStepsStore((state) => state.addSteps)

    const { t } = useTranslation("common", "motherTongueReplaceStep")

    const processHandler = () => {
        const stepId = nextStepId(item.id)
        if (stepId) {
            setActiveTab(stepId)
        } else {
            processDiaryStep.mutate(
                {
                    id: item.id,
                    diaryId: item.diaryId,
                },
                {
                    onSuccess: (data) => {
                        const setActive = true
                        addSteps(data, setActive)
                    },
                    onError: (error) => {
                        console.error("onError", error)
                    },
                }
            )
        }
    }

    return (
        <>
            <div className="flex flex-grow max-w-3xl">
                <div className="w-full rounded-2xlg shadow-lg pt-12 pb-6 px-8 bg-white min-h-[260px] text-lg max-w-3xl flex flex-col space-y-6">
                    <div
                        className="grow"
                        dir={isRtl(item.lang) ? "rtl" : "ltr"}
                    >
                        <Editor initialValue={editorData} />
                    </div>
                    <div className="flex flex-col items-center">
                        <MyButton
                            onClick={processHandler}
                            loading={processDiaryStep.isPending}
                        >
                            {t("common:next")}
                        </MyButton>
                    </div>
                </div>
            </div>

            <div className="pt-12 pb-4 w-96 min-w-64 h-[260px] text-base">
                <div className="text-base whitespace-pre-line">
                    {translateSuggestions && translateSuggestions.length > 0
                        ? t("motherTongueReplaceStep:foundSomething")
                        : t("motherTongueReplaceStep:nothingFound")}
                </div>

                <ul className="list-none mt-6 space-y-2">
                    {translateSuggestions &&
                        translateSuggestions.map((item) => (
                            <li key={item.id}>
                                <b>{item.original}</b> - {item.translate}
                            </li>
                        ))}
                </ul>
            </div>
        </>
    )
}

export default MotherTongueReplaceStep

export const enMotherTongueReplaceStepResources = {
    motherTongueReplaceStep: {
        foundSomething:
            "Excellent! Now let's replace the English words with their equivalents in the language you're learning:",
        nothingFound:
            "Great job! It looks like you've written this text entirely in your target language without using any words from your native language. Click 'Next' so we can check the text for grammar and spelling errors.",
    },
}

// "Excellent! Now let's replace the English words with their equivalents in the language you're learning."
// "Ready for the next step? Let's swap the English words for their counterparts in your target language."
// "Moving forward! Let's try using words from your target language instead of English."
// "Time to apply your new knowledge! We'll replace English words with the corresponding ones in your target language."
// "Progress is evident! Now we'll use words from your target language instead of English."
// "Great start! Let's move on to using words in the language you're learning."
// "You're on the right track! The next stage is replacing English words with their equivalents in your target language."
// "Step by step towards your goal! Now we're substituting English words with their counterparts in your target language."

export const ruMotherTongueReplaceStepResources = {
    motherTongueReplaceStep: {
        foundSomething:
            "Отлично! Теперь давайте заменим русские слова на их эквиваленты в изучаемом языке:",
        nothingFound:
            "Отлично! Похоже, вы написали этот текст полностью на изучаемом языке, не используя слов из родного. Нажмите 'Далее', чтобы мы могли проверить текст на грамматические и орфографические ошибки.",
    },
}

// "Готовы к следующему шагу? Заменим русские слова на их аналоги в изучаемом языке."
// "Двигаемся дальше! Попробуем использовать слова изучаемого языка вместо русских."
// "Время применить новые знания! Заменим русские слова на соответствующие в изучаемом языке."
// "Прогресс налицо! Теперь используем слова изучаемого языка вместо русских."
// "Отличное начало! Давайте перейдем к использованию слов на изучаемом языке."
// "Вы на верном пути! Следующий этап - замена русских слов на их аналоги в изучаемом языке."
// "Шаг за шагом к цели! Теперь заменяем русские слова на эквиваленты в изучаемом языке."
