import React, { useEffect, useState } from "react"
import Editor from "./Editor"
import MyButton from "./../ui/MyButton"

import { useTranslation } from "react-i18next"
import { isRtl } from "../Lang/Definitions"
import { isDemoEntry } from "./../Diary/helpers"

import {
    useUpdateDiaryStepMutation,
    useProcessDiaryStepMutation,
} from "../Diary/DiaryService"

import useDiaryStepsStore from "./../Diary/DiaryStepsStore"

function DraftStep({ item }) {
    const { t } = useTranslation("common", "draftStep")
    const { editorData } = item
    const updateDiaryStep = useUpdateDiaryStepMutation()
    const processDiaryStep = useProcessDiaryStepMutation()
    const nextStepId = useDiaryStepsStore((state) => state.nextStepId)
    const setActiveTab = useDiaryStepsStore((state) => state.setActiveTab)
    const addSteps = useDiaryStepsStore((state) => state.addSteps)
    const diary = useDiaryStepsStore((state) => state.diary)
    const updateStepEditorData = useDiaryStepsStore(
        (state) => state.updateStepEditorData
    )
    const [characterCount, setCharacterCount] = useState(0)

    const onChangeHandler = (editorState) => {
        // TODO проверять если изменения и только тогда сабмитить
        updateDiaryStep.mutate({
            id: item.id,
            diaryId: item.diaryId,
            editorData: editorState,
        })

        processDiaryStep.reset()

        setCharacterCount(calcCharactersCount(editorState))

        updateStepEditorData(item.id, editorState)
    }

    const processHandler = () => {
        const stepId = nextStepId(item.id)
        if (stepId) {
            setActiveTab(stepId)
        } else {
            processDiaryStep.mutate(
                {
                    id: item.id,
                    diaryId: item.diaryId,
                },
                {
                    onSuccess: (data) => {
                        const setActive = true
                        addSteps(data, setActive)
                    },
                    onError: (error) => {
                        console.error("onError", error)
                        if (error.cause) {
                            console.log("Server error details:", error.cause)
                            // Обработайте ошибку сервера, например, показав уведомление пользователю
                            // showErrorNotification(error.cause.errorMessage)
                        }
                    },
                }
            )
        }
    }

    return (
        <>
            <div className="flex flex-grow max-w-3xl">
                <div className="w-full rounded-2xlg shadow-lg pt-12 pb-6 px-8 bg-white min-h-[260px] text-lg flex flex-col space-y-6">
                    <div
                        className="grow"
                        dir={isRtl(item.lang) ? "rtl" : "ltr"}
                    >
                        <Editor
                            initialValue={editorData}
                            onChangeHandler={onChangeHandler}
                            isWritable
                        />
                    </div>

                    <div className="flex justify-between items-center">
                        <div className="w-24"></div>

                        <div className="flex flex-col items-center relative w-full">
                            <ErrorMessage mutationResult={processDiaryStep} />
                            <MyButton
                                onClick={processHandler}
                                loading={processDiaryStep.isPending}
                            >
                                {nextStepId(item.id)
                                    ? t("common:next")
                                    : t("draftStep:checkText")}
                            </MyButton>
                        </div>

                        <div className="w-24 text-right">
                            <CharacterCount count={characterCount} />
                        </div>
                    </div>
                </div>
            </div>

            {isDemoEntry(diary) ? (
                <div className="p-4 bg-amber-600 text-white font-semibold text-sm rounded-lg w-96 min-w-64  whitespace-pre-line h-min">
                    {t("draftStep:demoDisclamer")}
                </div>
            ) : (
                <div className="pt-12 pb-4 w-96 min-w-64 h-[260px] text-base whitespace-pre-line">
                    {t("draftStep:motivationSpeech")}
                </div>
            )}
        </>
    )
}

const ErrorMessage = ({ mutationResult }) => {
    const [isVisible, setIsVisible] = useState(false)
    const [message, setMessage] = useState("")
    useEffect(() => {
        if (mutationResult.error) {
            setMessage(mutationResult.error.message)
            setIsVisible(true)
            const timer = setTimeout(() => setIsVisible(false), 5000) // Hide after 5 seconds
            return () => clearTimeout(timer)
        } else {
            setIsVisible(false)
        }
    }, [mutationResult.error])

    return (
        <div
            className={`
        absolute bottom-6 left-0 right-0
        transition-opacity duration-500 ease-in-out
        ${isVisible ? "opacity-100" : "opacity-0"}
      `}
        >
            <p className="text-amber-600 text-sm text-center">{message}</p>
        </div>
    )
}

export default DraftStep

export const enDraftStepResources = {
    draftStep: {
        checkText: "Analyze Writing",
        motivationSpeech:
            "Don't worry about making mistakes or using words in English. Focus on the writing process and enjoy it.",
        demoDisclamer:
            'This is the draft where every diary entry begins.\n\nGloisia encourages free expression of thoughts, allowing the use of your native language in entries. Can\'t remember a word? Simply write it in your native language. Our system recognizes mixed text, allowing you to focus on describing the details of your life rather than on language perfection. The more detailed your entries are, the more effectively Gloisia can help identify and address gaps in your knowledge.\n\nClick "Next" to see identified errors and recommendations for correcting them.',
    },
}

export const ruDraftStepResources = {
    draftStep: {
        checkText: "Анализировать текст",
        motivationSpeech:
            "Не переживайте о том, чтобы делать ошибки или использовать слова на русском. Сосредоточьтесь на процессе письма и получайте от него удовольствие.",
        demoDisclamer:
            'Это черновик, с которого начинается каждая запись в дневнике.\n\nGloisia поощряет свободное выражение мыслей, допуская использование родного языка в записях. Не можете вспомнить слово? Просто напишите его на родном языке. Наша система распознает смешанный текст, позволяя сосредоточиться на описании деталей вашей жизни, а не на совершенстве языка. Чем подробнее ваши записи, тем эффективнее Gloisia поможет выявить и устранить пробелы в знаниях.\n\nНажмите "Далее", чтобы увидеть выявленные ошибки и рекомендации по их исправлению.',
    },
}

// Русские варианты:

// "Проверить текст"
// "Анализ текста"
// "Оценить написанное"
// "Получить обратную связь"
// "Улучшить текст"
// "Проверить написанное"
// English versions:

// "Check Text"
// "Analyze Text"
// "Evaluate Writing"
// "Get Feedback"
// "Improve Text"
// "Check Writing"

function calcCharactersCount(editorState) {
    const getTextFromNode = (node) => {
        if (typeof node.text === "string") {
            return node.text
        }
        if (Array.isArray(node.children)) {
            return node.children.map(getTextFromNode).join("")
        }
        return ""
    }

    // Получаем текст из всей структуры
    const plainText = editorState.map(getTextFromNode).join("\n")

    // Подсчитываем количество символов
    const characterCount = plainText.length

    return characterCount
}

const CharacterCount = ({ count, limit = 1024, warningThreshold = 850 }) => {
    const getOpacity = () => {
        if (count === 0 || count <= warningThreshold) return "opacity-0"
        return "opacity-100"
    }

    const getColor = () => {
        if (count > limit) return "text-amber-600"
        if (count > limit * warningThreshold) return "text-amber-500"
        return "text-gray-500"
    }

    return (
        <div
            className={`text-sm transition-all duration-300 ${getOpacity()} ${getColor()}`}
        >
            {count} / {limit}
        </div>
    )
}
